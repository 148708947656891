a<template>
    <admin-wrapper>
        <app-loader :value="$apollo.loading"/>
        <v-card flat v-if="accountData && (accountData.isSumoUser || accountData.ltdCustomer)">
            <v-container>
                <nice-header>
                    Lifetime deal
                </nice-header>
                <v-card-text>
                    You have signed up to the lifetime deal
                    <span v-if="accountData.isSumoUser">via <a href="https://appsumo.com">AppSumo</a></span>
                </v-card-text>
            </v-container>
        </v-card>
    <v-card flat v-else-if="subscription">
        <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
        <v-container>

            <nice-header>
                Account and billing details
                <template v-slot:extra>
                    <v-spacer></v-spacer>
                    <v-btn
                        rounded
                        outlined
                        color="primary"
                        :href="billingPortalUrl"
                    >Manage subscription</v-btn>
                </template>
            </nice-header>

            <v-subheader v-if="subscription">
                Rentify billing is managed securely by Stripe. To upgrade, change or cancel your plan, use the Manage subscription button above
            </v-subheader>
            <v-subheader v-else>
                You currently have a free account
            </v-subheader>
        </v-container>
    </v-card>

        <v-card flat text v-if="subscription">
        <v-container>
            <v-row v-if="subscription">
                <v-col cols="12" md="4">
                    <v-list two-line subheader>
                        <v-subheader>
                            Plan
                        </v-subheader>
                        <list-item
                                :title="subscription.status | sentenceCase"
                                subtitle="Current subscription status"
                                icon="mdi-list-status"
                        ></list-item>
                        <v-divider inset></v-divider>
                        <list-item
                                :title="subscription.plan.interval | interval"
                                subtitle="Billing frequency"
                                icon="mdi-calendar-refresh"
                        />
                    </v-list>
                </v-col>
                <v-col cols="12" md="4">
                    <v-list two-line subheader>
                        <v-subheader>Product</v-subheader>
                        <list-item
                                :title="subscription.plan.product.name"
                                subtitle="Product name"
                                icon="mdi-animation"
                        />
                        <v-divider inset></v-divider>
                        <list-item
                                :title="subscription.plan.product.description || 'Not available'"
                                subtitle="Product description"
                                icon="mdi-label"
                        />
                    </v-list>
                </v-col>
                <v-col cols="12" md="4">
                    <v-list two-line subheader>
                        <v-subheader>
                            Price
                        </v-subheader>
                        <list-item
                                v-if="subscription"
                                :title="subscription.plan | price"
                                subtitle="Price"
                                icon="mdi-currency-usd"
                        ></list-item>
                        <v-divider inset></v-divider>

                    </v-list>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-container v-else-if="!accountData.isSumoUser && !accountData.ltdCustomer">
            <v-row align="center" justify="center">
                <v-col cols="12">
                    <v-row class="align-center justify-center">
                        <v-col cols="auto">
                            <v-btn-toggle
                              v-model="plan"
                              class="white align-center"
                              label="Billing"
                              color="primary"
                              dense
                              rounded
                              group
                              mandatory
                            >
                              <v-btn value="month">
                                Monthly billing
                              </v-btn>

                              <v-btn value="year">
                                Annual billing
                              </v-btn>

                            </v-btn-toggle>
                          </v-col>

                    </v-row>

                </v-col>

                <v-col cols="12" md="4" v-if="plan !== 'once'">
                    <v-card tile>
                        <nice-header>
                            Free
                            <template v-slot:extra>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    outlined
                                    rounded
                                    disabled
                                >
                                    Selected
                                </v-btn>
                            </template>
                        </nice-header>
                        <v-card-title class="justify-center">
                            <div class="justify-center">
                                <div class="display-1 font-weight-light">£ 0 per month</div>
                                <div class="body-2 justify-center text-center grey--text">
                                    10% commission added to all bookings
                                </div>
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <v-list>
                                <template v-for="(item, i) in freeBullets">
                                    <v-list-item :key="`1-${i}`">
                                        <v-list-item-title>{{item}}</v-list-item-title>
                                    </v-list-item>
                                    <v-divider :class="!!item ? undefined : 'white'" :key="`1-div-${i}`"></v-divider>
                                </template>
                            </v-list>
                            </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" md="4" v-if="plan !== 'once'">
                    <v-card tile>
                        <nice-header>
                            Standard
                            <template v-slot:extra>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    outlined
                                    rounded
                                    :loading="loading"
                                    @click="getCheckout('Standard')"
                                >
                                    Select this plan
                                </v-btn>
                            </template>
                        </nice-header>
                        <v-card-title class="justify-center">
                            <div class="justify-center">
                                <div class="display-1 font-weight-light">
                                    <span v-if="plan === 'year'">£ 29 per month</span>
                                    <span v-else-if="plan === 'month'">£ 39 per month</span>
                                </div>
                                <div class="body-2 justify-center text-center grey--text" v-if="plan === 'once'">Billed once</div>
                                <div class="body-2 justify-center text-center grey--text" v-else>Billed every {{plan}}</div>
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <v-list>
                                <template v-for="(item, i) in basicBullets">
                                    <v-list-item :key="`1-${i}`">
                                        <v-list-item-title>{{item}}</v-list-item-title>
                                    </v-list-item>
                                    <v-divider :class="!!item ? undefined : 'white'" :key="`1-div-${i}`"></v-divider>
                                </template>
                            </v-list>
                            </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" md="4">
                    <v-card tile>
                        <nice-header>
                            Advanced
                            <template v-slot:extra>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    outlined
                                    rounded

                                    :loading="loading"
                                    @click="getCheckout(plan === 'once' ? 'LTD' : 'Advanced')"
                                >
                                    Select this plan
                                </v-btn>
                            </template>
                        </nice-header>
                        <v-card-title class="justify-center">
                            <div class="justify-center">
                                <div class="display-1 font-weight-light">
                                    <span v-if="plan === 'year'">£ 59 per month</span>
                                    <span v-else>£ 69 per month</span>
                                </div>
                                <div class="body-2 justify-center text-center grey--text">Billed every {{plan}}</div>
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <v-list>
                                <template v-for="(item, i) in plan === 'once' ? ltdBullets : advancedBullets">
                                    <v-list-item :key="`1-${i}`">
                                        <v-list-item-title>{{item}}</v-list-item-title>
                                    </v-list-item>
                                    <v-divider :class="!!item ? undefined : 'white'" :key="`1-div-${i}`"></v-divider>
                                </template>
                            </v-list>
                            </v-card-text>
                    </v-card>
                </v-col>

            </v-row>
        </v-container>
    </admin-wrapper>
</template>

<script>
    import { subscription } from '@/graphql'
    import { billingPortalUrl } from '@/graphql'
    import {getAdminCheckout} from '@/graphql'
    import { accountData } from '@/graphql'
    import moment from 'moment'

    export default {
        apollo: {subscription, billingPortalUrl, accountData},
        methods: {
            openPortal () {
                window.location = this.billingPortalUrl
            },
            getCheckout (product) {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: getAdminCheckout,
                    variables: { product, plan: this.plan },
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL error: ', '');
                    this.loading = false
                }).then(({ data }) => {
                    const { publishableKey, sessionId } = data.getAdminCheckout;
                    // eslint-disable-next-line
                    var stripe = Stripe(publishableKey);
                    stripe.redirectToCheckout({ sessionId })
                })
            }
        },
        data: () => ({
            loading: false,
            error: false,
            errorMessage: null,
            plan: 'year',
            freeBullets: [
                '1 user account',
                '1 store',
                'Accept credit card payments online',
                '10% commission on orders, plus Stripe fees',
                'Rentify Branding',
                '',
                '',
            ],
            basicBullets: [
                '3 user accounts',
                '1 store',
                'Accept credit card payments online',
                '0% commission on orders, Stripe fees only',
                'Remove Rentify branding',
                '7 day free trial',
                'Custom store domains',
                
            ],
            advancedBullets: [
                'Unlimited user accounts',
                'Unlimited stores',
                'Accept credit card payments online',
                '0% commission on orders, Stripe fees only',
                'Remove Rentify branding',
                '30 day free trial',
                'Custom store domains',
                
            ],
            ltdBullets: [
                'Unlimited user accounts',
                'Unlimited stores',
                'Accept credit card payments online',
                '0% commission on orders, Stripe fees only',
                'Remove Rentify branding',
                '30 money back guarantee',
                'Custom store domains',
            ]
        }),
        filters: {
            sentenceCase(value) {
                return value[0].toUpperCase() + value.slice(1,)
            },
            interval(value) {
                switch (value) {
                    case 'MONTH':
                        return 'Monthly';
                    case 'YEAR':
                      return 'Annual'
                    default:
                        return 'Once'
                }
            },
            price(plan) {
              return `${plan.amount / 100} ${plan.currency.toUpperCase()} per ${plan.interval.toLowerCase()}`
            },
            niceDate (value) {
                return moment(value).calendar()
            }
        }
    }
</script>
